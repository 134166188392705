import http from "./http"

/** 
    @name { 奖品列表 } 
    @params { token } 
    @data {
        {
        "id": 0,
        "text": "谢谢参与"
        },
    }
*/
export const $apiPrizeList = (data) => http({ method: 'post', url: '/prize/list', params: data })


/**
    @name { 进行抽奖 } 
    @params { token} 
    @data {
        "result": "剩余次数"
        "result": "抽奖结果"
    }
*/
export const $apiPrizeInfo = (data) => http({ method: 'post', url: '/daily/prize', params: data })

// 交易抽奖信息： 次数，金额
export const $apiDealPrizeInfo = (data) => http({ method: 'post', url: '/gift/amount', params: data })
// 交易抽奖
export const $apiDealPrize = (data) => http({ method: 'post', url: '/trading/draw', params: data })





